import React from "react";
import "./Div2.css";

export const Div2 = () => {
  return (
    <div className="tns-div2-container">
      <div className="tns-div2-heading">
        <span>His Major Address</span>
      </div>

      <div className="tns-div2">
        <div className="tns-div2-block">
          <div className="tns-div2-block-img-container">
           
          </div>

          <p>
            The life and work of Prof. Samanta has been the subject of a number
            of films, documentaries, television series,{" "}
          </p>
        </div>

        <div className="tns-div2-block">
          <div className="tns-div2-block-img-container">
            
          </div>

          <p>
            The life and work of Prof. Samanta has been the subject of a number
            of films, documentaries, television series,{" "}
          </p>
        </div>

        <div className="tns-div2-block">
          <div className="tns-div2-block-img-container">
            
          </div>

          <p>
            The life and work of Prof. Samanta has been the subject of a number
            of films, documentaries, television series,{" "}
          </p>
        </div> 
        
      </div>
    </div>
  );
};
