import React from "react";
import "../Main/Mainbar.css";
import { Home } from "../Home/Home";

export const Mainbar = () => {
  


  return (
    <div className="main-bar">
     <Home/>
    </div>
  );
};
